<template>
  <div class="col">
    <!-- Start Add Content Section -- Editor Here  -->
    <div
      class="text-right mx-auto p-5 border border-gray bg-white font-weight-bolder"
      style="width: 80%; max-width: 80rem"
      v-if="add_content_dialog"
    >
      <p class="h3">{{ $t("add_content") }}</p>
      <el-form
        :model="added_content"
        ref="addContentRef"
        :rules="addContentRules"
        v-loading="$store.getters['courses/load']"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('title')" prop="title">
              <el-input
                v-model="added_content.title"
                style="width: 100%"
              ></el-input>
            </el-form-item>
            <!-- <div v-if="isTitleWitWhitespace" class="title_is_empty">
              يجب ادخال عنوان
            </div> -->
          </el-col>

          <!-- <el-col :span="24" v-if="added_content.type == 7">
            <label for="activity_link" :span="24">الرابط </label>
            <el-form-item
              :label="activity_link"
              prop="activity_link"
              id="activity_link"
            >
              <el-input v-model="added_content.link"></el-input>
            </el-form-item>
          </el-col> -->

          <el-col :span="24">
            <el-form-item :label="$t('description')" prop="description">
              <page
                api-key="5134dsngirrl0brc1i314hwj587bk140g03tif8xq86wb1n7"
                v-model="added_content.content"
                :init="init_en"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer w-50 flex-column flex-lg-row d-md-flex my-3 mr-auto"
        dir="ltr"
      >
        <el-button
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="add_content_dialog = false"
          >{{ $t("cancel") }}
        </el-button>
        <el-button
          class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
          type="primary"
          @click="save_content_add"
          >{{ $t("save_only") }}
        </el-button>
      </span>
    </div>
    <!-- End Add Content Section -- Editor Here  -->

  </div>
</template>

<script>
import page from "@tinymce/tinymce-vue";
import Swal from "sweetalert2";
import exam from "@/views/courses/exam";
import html2canvas from "html2canvas";
import $ from "jquery";
import screenshot from "@/utils/helper.js";

export default {
  components: { page, exam },
  props:["add_content_dialog","courseData","id"],

  data() {
    return {
      child_id: null,
      options: [
        {
          value: "HTML",
          label: "HTML",
        },
        {
          value: "CSS",
          label: "CSS",
        },
        {
          value: "JavaScript",
          label: "JavaScript",
        },
      ],
      value: [],

      activeNames: "0",
      examFlag: true,

      // add content data
      // add_content_dialog: true,
      added_content: {},
      addContentRules: {
        title: [
          {
            required: true,
            message: this.$t("title_required"),
            trigger: "blur",
          },
        ],

        type: [
          {
            required: true,
            message: this.$t("kind_required"),
            trigger: "change",
          },
        ],

        content: [
          {
            required: true,
            message: this.$t("lesson_content_required"),
            trigger: "blur",
          },
        ],
      },

      // this to show file name to user since we hide original input for styling
      add_content_file_name: "",
      types: [
        { value: 1, label: this.$t("video") },
        { value: 4, label: this.$t("activity") },
        { value: 2, label: this.$t("image") },
        { value: 3, label: this.$t("digital_activity") },
        { value: 5, label: this.$t("file") },
        { value: 6, label: this.$t("blank") },
        { value: 7, label: "storyline" },
      ],

      // this is used for both add content and add lesson forms
      init_en: {
        //       selector: 'textarea',  // change this value according to your HTML
        // images_upload_url: 'postAcceptor.php',
        // automatic_uploads: false,
        images_upload_handler: this.imageUpload,
        height: 260,
        mode: "none",
        menubar: "media",
        language: "en",
        toolbar:
          "undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify  | media image link | numlist bullist | forecolor backcolor | ltr rtl",
        plugins: ["table charmap imagetools  textpattern media image link"],
      },

      // edit lesson data
      edit_lesson_dialog: false,
      edited_lesson: {},
      editLessonRules: {
        title: [
          {
            required: true,
            message: this.$t("title_required"),
            trigger: "blur",
          },
        ],
      },

      // submit solution data
      solution_dialog: false,
      solution: {},
      solutionRules: {
        message: [
          {
            required: true,
            message: this.$t("message_required"),
            trigger: "blur",
          },
        ],

        student_id: [
          {
            required: true,
            message: this.$t("children_required"),
            trigger: "change",
          },
        ],
      },
      // this to show file name to user since we hide original input for styling
      submit_solution_file_name: "",
      isTitleWitWhitespace: false,
    };
  },

  computed: {
    courseData() {
      return this.$store.getters["courses/course"];
    },
    students() {
      return this.$store.getters["courses/teachers"];
    },
  },

  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      console.log(this.$route.params.id);
      this.$store
        .dispatch("courses/get", this.$route.params.id)
        .then((res) => {});
      this.$store.dispatch("user/get", this.auth().id);
    },

    save_content_add() {
      let pattern = /[a-zA-Z]/;
      let string = this.added_content.title;
      let ans = pattern.test(string);
      if (ans == false) {
        // this.isTitleWitWhitespace = true;
        Swal.fire({
          text: "من فضلك ادخل العنوان",
          icon: "warning",
          confirmButtonClass: "btn btn-success btn-fill",
          confirmButtonText: "موافق",
          buttonsStyling: false,
        });
        return false;
      } else {
        this.isTitleWitWhitespace = false;
        this.$refs["addContentRef"].validate((valid) => {
          if (valid) {
            let formData = new FormData();
            for (let course in this.added_content) {
              formData.append(`${course}`, this.added_content[course]);
            }

            // check if there is a file to upload since user has option of not uploading file depending on content type
            if (this.$refs["add_content_file"]) {
              // add add content image
              formData.append("file", this.$refs["add_content_file"].files[0]);
            }

            this.$store
              .dispatch("courses/save", {
                id: this.id ? `/${this.id}` : "",
                query: formData,
              })
              .then((_) => {
                this.refresh();
                this.add_content_dialog = false;
                this.add_content_file_name = "";
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },

  },
};
</script>

<style scoped>
::v-deep .el-form-item {
  position: relative;
}
::v-deep .el-form--label-top .el-form-item__label {
  position: relative;
}

::v-deep .el-form-item__label:before {
  position: absolute;
  left: -12px;
}

::v-deep .el-dialog__headerbtn {
  left: 20px;
  right: unset;
}

::v-deep .el-form-item__error {
  left: unset;
  right: 0;
  font-size: 0.9rem;
}

/* form select inputs  */
.el-select-dropdown__item {
  padding: 5px 40px;
  text-align: right;
}

/* style for file input  */
.file_input {
  position: relative;
}
.input_file_placeholder {
  position: absolute;
  border: none;
  border-bottom: 1px solid #dcdfe6;
  width: calc(100% - 2em);
}

.input_file_placeholder::before {
  content: "";
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: 10;
  right: 0;
}
#ind {
  z-index: 1;
}
#hl {
  background-color: red;
  border: 1px solid black;
  z-index: 55;
}
#img2 {
  background-color: red;
  border: 1px solid black;
  z-index: 55;
}
.labls {
  /* border-bottom: 1px solid #f7f8f9; */
  padding: 5px;
  margin-right: 6px;
  cursor: pointer;
}
.labls:hover {
  background-color: #f7f8f9;
}
.labls2 {
  /* border-bottom: 1px solid #f7f8f9; */
  /* padding: 5px;
  margin-right: 6px; */
  cursor: pointer;
}
.labls2:hover {
  background-color: #f7f8f9;
}
.wdth {
  width: 50%;
}
#selectchildren {
  padding: 7px;
}
</style>

<style lang="scss" scoped>
.title_is_empty {
  position: absolute;
  top: 100px;
  font-size: 0.9rem;
  color: #f56c6c;
}

::v-deep .el-textarea__inner {
  height: 300px;
  resize: none;
}
</style>
